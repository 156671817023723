import { Storage, Router, Log } from '@lightningjs/sdk';
import { DrmType } from '@sky-uk-ott/core-video-sdk-js';
import AppConfigFactorySingleton from '../../config/AppConfigFactory';
import { generateDeviceID, getDeviceId, storeDeviceId } from '../DeviceIdUtils';
import { fontSpecs, computeFontSource } from './util/fonts';
import { DebugControllerSingleton } from '../../util/debug/DebugController';
import { getRenderPrecision, isSamsung, isVizio, isXbox } from '../../helpers';
import { Keys, STORAGE_KEYS, USER_OPT_OUT_PREFERENCE, ROUTE, PLAYER_SIZE } from '../../constants';
import BaseAnnounce from '../tts/Announces/BaseAnnounce';
import { ConsentManagement } from '../cmp/ConsentManagement';
export const TV_PLATFORM_TAG = 'TV Platform';
export var PlatformSubscriptionType;
(function (PlatformSubscriptionType) {
    PlatformSubscriptionType["VOICE"] = "voiceGuidance";
    PlatformSubscriptionType["VOICE_CONTROL"] = "voiceControl";
    PlatformSubscriptionType["CC"] = "closedCaptions";
    PlatformSubscriptionType["BACKGROUND"] = "background";
    PlatformSubscriptionType["FOREGROUND"] = "foreground";
})(PlatformSubscriptionType || (PlatformSubscriptionType = {}));
/**
 * Generic subscription wrapper since platforms
 * differ on how they clear the subscriptions
 * @class
 */
export class SubscriptionWrapper {
    unsubscribe() { }
}
export default class BasePlatform {
    constructor() {
        this._subscriptions = [];
        this._devicesSdkConfig = {};
        this._drmType = DrmType.Widevine;
        this.platformFlags = Object.freeze({
            // on some remotes, there is only one 'back' button and no 'return' button
            hideReturnToTopHint: isXbox() || isSamsung(),
            shouldToggleRackOnBackspace: isXbox(),
            showPreLoading: isXbox(),
            useHttpForLogoURL: isSamsung(),
            singleThreadVideoBehavior: isVizio(),
            useHashRouter: isSamsung(),
        });
        this.checkDeepLinkNavigation = () => { };
        this.setPreview = async () => { };
        /**
         * Initialize fonts for the platform
         */
        this.initializeFonts = () => {
            const fontLoadingPromises = fontSpecs.map((spec) => {
                if (spec.src) {
                    const fontFace = new FontFace(spec.family, computeFontSource(spec), spec.descriptors);
                    document.fonts.add(fontFace);
                    return fontFace.load();
                }
                return null;
            });
            return Promise.all(fontLoadingPromises);
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.subscribe = (evt, callback) => {
            return undefined;
        };
    }
    get deviceId() {
        return getDeviceId();
    }
    set deviceId(deviceId) {
        storeDeviceId(deviceId);
    }
    get deviceType() {
        return this._deviceType;
    }
    get devicePartnerId() {
        return window.DEVICE_TYPE || '';
    }
    get drmType() {
        return this._drmType;
    }
    get devicesSdkConfig() {
        return this._devicesSdkConfig;
    }
    get lemonadePlatform() {
        return this._lemonadePlatform;
    }
    get bffPlatform() {
        return this._bffPlatform;
    }
    get appIdentifier() {
        return this._appIdentifier;
    }
    get platformName() {
        return this._platformName;
    }
    get streamingProtocol() {
        return this._streamingProtocol;
    }
    get capabilities() {
        return {
            externalAppLinking: false,
            // Setting true as a default, but this needs to be tested on each device
            // and add the override for platforms that don't support concurrent streams
            concurrentStreams: true,
        };
    }
    get deviceInfo() {
        return {
            primaryHardwareType: 'Unknown',
            model: 'None',
            version: 'None',
            manufacturer: 'None',
            vendor: 'None',
            osName: 'Linux',
            osFamily: 'Linux',
            osVendor: 'None',
            osVersion: 'None',
            browserName: 'Symbian Browser',
            browserVendor: 'Netscape',
            browserVersion: 'None',
            userAgent: 'None',
            displayWidth: 0,
            displayHeight: 0,
            displayPpi: 0,
            diagonalScreeenSize: 0,
            connectionIp: 'None',
            connectionPort: 0,
            connectionType: 'None',
            connectionSecure: false,
            applicationId: 'None',
        };
    }
    async init() {
        await this.generateDeviceId();
    }
    tts() {
        return {
            speak(toSpeak, notification = false) {
                return new BaseAnnounce(toSpeak, notification);
            },
            cancel() {
                window.speechSynthesis.cancel();
            },
        };
    }
    /**
     * Implementation required
     */
    exit() {
        this._subscriptions.forEach((subscription) => subscription.unsubscribe());
        this._subscriptions = [];
    }
    dismissLoadingScreen() { }
    getStageSettings() {
        return {
            devicePixelRatio: window.devicePixelRatio || 1,
        };
    }
    scaleVideoProperty(val, type) {
        return type === PLAYER_SIZE.FULL ? Math.round(val * getRenderPrecision()) : val;
    }
    getNumbersKeyMapping() {
        return {
            48: '0',
            49: '1',
            50: '2',
            51: '3',
            52: '4',
            53: '5',
            54: '6',
            55: '7',
            56: '8',
            57: '9',
        };
    }
    getAtoZAndNumberKeyMapping(numbersKeyMapping = false) {
        const numbers = numbersKeyMapping ? this.getNumbersKeyMapping() : {};
        return Object.assign(Object.assign({}, numbers), { 65: 'A', 66: 'B', 67: 'C', 68: 'D', 69: 'E', 70: 'F', 71: 'G', 72: 'H', 73: 'I', 74: 'J', 75: 'K', 76: 'L', 77: 'M', 78: 'N', 79: 'O', 80: 'P', 81: 'Q', 82: 'R', 83: 'S', 84: 'T', 85: 'U', 86: 'V', 87: 'W', 88: 'X', 89: 'Y', 90: 'Z' });
    }
    getPlatformKeyMapping() {
        return {
            36: Keys.RETURN,
            38: Keys.ARROW_UP,
            40: Keys.ARROW_DOWN,
            37: Keys.ARROW_LEFT,
            39: Keys.ARROW_RIGHT,
        };
    }
    async generateDeviceId() {
        await generateDeviceID();
    }
    getStorageBasedOptOut() {
        const userOptOutFromStorage = Number(Storage.get(STORAGE_KEYS.USER_OPT_OUT));
        const storageOptOut = userOptOutFromStorage || ConsentManagement.getCmpOptOut();
        return storageOptOut === USER_OPT_OUT_PREFERENCE.DISALLOW_SALE ||
            DebugControllerSingleton.userOptOut
            ? USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
            : USER_OPT_OUT_PREFERENCE.ALLOW_SALE;
    }
    getUserOptOut() {
        return this.getStorageBasedOptOut();
    }
    getAdvertiserId() {
        var _a, _b;
        return (this._advertiserId ||
            ((_b = (_a = AppConfigFactorySingleton.config) === null || _a === void 0 ? void 0 : _a.core_video_sdk) === null || _b === void 0 ? void 0 : _b.deviceAdvertisingId) ||
            '');
    }
    getDeviceAdvertisingIdType() {
        var _a, _b;
        return (this._deviceAdvertisingIdType ||
            ((_b = (_a = AppConfigFactorySingleton.config) === null || _a === void 0 ? void 0 : _a.core_video_sdk) === null || _b === void 0 ? void 0 : _b.deviceAdvertisingIdType) ||
            '');
    }
    getAdvertiserDeviceType() {
        return 'dpid';
    }
    async getModelNumber() {
        return 'None';
    }
    getFirmware() {
        return 'None';
    }
    handleDeepLink() {
        console.warn('You must implement this method!');
    }
    exitToPeacock() {
        Router.navigate(ROUTE.home);
    }
    // force garbage collection where referenced
    getForceGC() {
        return true;
    }
    // adjustment originally added for vizio issues
    getAllowLinearGradient() {
        return true;
    }
    reportError(error) {
        Log.error(error);
    }
    historyBack() {
        history.go(-1);
    }
}
