import { Language, Storage } from '@lightningjs/sdk';
import { getBrandName, getMpid } from '../../helpers';
import AppConfigFactorySingleton from '../../config/AppConfigFactory';
import { STORAGE_KEYS } from '../../constants/common';
import { getAccessToken, getUserProfile } from '../../api/Identity';
import { ErrorType } from '../tv-platform/types';
import TVPlatform from '../tv-platform';
/**
 * Determines if the data download should include profile data or not.
 * By default if no fetch type is passed, we use APP_DATA_ONLY.
 * If cross device is in scope, pass in APP_DATA_AND_SYNC_PROFILE
 */
var FetchType;
(function (FetchType) {
    FetchType["APP_DATA_ONLY"] = "APP_DATA_ONLY";
    FetchType["APP_DATA_AND_PROFILE"] = "APP_DATA_AND_PROFILE";
    FetchType["APP_DATA_AND_SYNC_PROFILE"] = "APP_DATA_AND_SYNC_PROFILE";
})(FetchType || (FetchType = {}));
const getBaseHeaders = (consentString = '') => {
    var _a;
    const userProfile = getUserProfile();
    const forgerockId = (_a = userProfile === null || userProfile === void 0 ? void 0 : userProfile._data) === null || _a === void 0 ? void 0 : _a._id;
    const mpid = getMpid();
    const identifier = forgerockId || mpid || '';
    const headers = {
        'OT-CDN-Location': 'cdn.cookielaw.org',
        'OT-App-Id': AppConfigFactorySingleton.config.one_trust_id,
        'OT-SDK-Version': AppConfigFactorySingleton.config.one_trust_version,
        'OT-Device-Type': 'ctv',
        'OT-Consent-String': consentString || Storage.get(STORAGE_KEYS.CMP_CONSENT_STRING) || '',
        'OT-Identifier': identifier,
        'OT-Sync-Profile-Auth': '',
        'OT-Fetch-Type': '',
        'OT-Language': (Language.get() || 'EN').toLowerCase(),
        'OT-Country-Code': 'US',
        'OT-Region-Code': '',
    };
    if (consentString) {
        try {
            const decodedConsent = JSON.parse(atob(consentString));
            if (decodedConsent.dsId !== identifier) {
                headers['OT-Identifier-UpdateType'] = 'Rename-Identifier';
            }
        }
        catch (e) {
            TVPlatform.reportError({
                type: ErrorType.OTHER,
                payload: e,
                description: 'Invalid base64',
            });
            throw e;
        }
    }
    return headers;
};
export const getSaveApiHeaders = (consentString = '') => new Headers(Object.assign(Object.assign({}, getBaseHeaders(consentString)), { 'Content-Type': 'application/json', 'OT-Identifier-Type': '', 'OT-GPP-String': Storage.get(STORAGE_KEYS.CMP_GPP_STRING) || '' }));
export const getBannerPreferenceHeaders = (consentString = '') => new Headers(Object.assign(Object.assign({}, getBaseHeaders(consentString)), { 'OT-Force-Fetch': 'false' }));
export const getOneTrustTokenHeaders = () => {
    return new Headers({
        'API-Version': AppConfigFactorySingleton.config.access_vod.idm_access.apiVersion,
        'X-IDM-Brand-Source': `${getBrandName()}_oneapp`,
        idm_tx_ref: crypto.randomUUID(),
        fr_token: getAccessToken() || '',
    });
};
