import { COLORS, FONT_FACE } from '../../../constants';
const commonProperties = {
    w: 1246,
    color: COLORS.black,
};
const commonTextProperties = {
    fontSize: 33,
    wordWrapWidth: 1061,
    textBaseline: 'Top',
    fontFace: FONT_FACE.light,
};
const TAGS_TO_NORMALIZE = ['B', 'A', 'U', 'P'];
export const normalizeTextBlocks = (childNode) => {
    const textBlocks = [];
    let currentBlock = textBlocks.length;
    Array.prototype.forEach.call(childNode.childNodes, (childNode) => {
        if (!textBlocks[currentBlock])
            textBlocks[currentBlock] = '<p>';
        if (TAGS_TO_NORMALIZE.includes(childNode.tagName)) {
            textBlocks[currentBlock] += childNode.outerHTML;
        }
        else if (childNode.nodeType === Node.TEXT_NODE) {
            textBlocks[currentBlock] += childNode.textContent;
        }
        else if (childNode.tagName === 'BR') {
            textBlocks[currentBlock] += '</p>';
            currentBlock++;
        }
    });
    return new DOMParser().parseFromString(textBlocks.join(''), 'text/html').body;
};
export const generateTextElementsByHtml = (childNode) => {
    var _a;
    if (childNode.nodeType === Node.TEXT_NODE) {
        if ((_a = childNode.data) === null || _a === void 0 ? void 0 : _a.match(/^\s+$/)) {
            // Text is really just an empty space, interpret as a <br/>
            return {
                w: 1246,
                h: 2,
            };
        }
        return generateTextNode(childNode);
    }
    if (childNode.children && childNode.children.length === 0) {
        return generateTextElementByTagName(childNode);
    }
    else {
        if (childNode.tagName === 'P' && childNode.getElementsByTagName('u').length > 0) {
            childNode.innerHTML = childNode.innerHTML.replace(/<u>|<\/u>/g, '');
        }
        const newElements = generateSpecialElements(childNode);
        if (newElements)
            return newElements;
        if (childNode.tagName === 'P' && childNode.getElementsByTagName('u').length > 0) {
            childNode.innerHTML = childNode.innerHTML.replace(/<u>|<\/u>/g, '');
        }
        else
            return Array.prototype.map.call(childNode.childNodes, generateTextElementsByHtml);
    }
};
const generateSpecialElements = (childNode) => {
    if ((childNode.tagName === 'P' || childNode.tagName === 'BODY') &&
        childNode.getElementsByTagName('a').length === childNode.children.length) {
        return Object.assign(Object.assign({}, commonProperties), { text: Object.assign(Object.assign({}, commonTextProperties), { text: childNode.innerText }) });
    }
    else if (childNode.tagName === 'P' &&
        childNode.children.length > 0 &&
        childNode.getElementsByTagName('b').length > 0) {
        return Object.assign(Object.assign({}, commonProperties), { text: Object.assign(Object.assign({}, commonTextProperties), { text: childNode.innerHTML, advancedRenderer: true }) });
    }
};
const generateTextElementByTagName = (childNode) => {
    switch (childNode.tagName) {
        case 'P':
        case 'A':
        case 'DIV': {
            return Object.assign(Object.assign({}, commonProperties), { text: Object.assign(Object.assign({}, commonTextProperties), { text: childNode.innerHTML }) });
        }
        case 'B':
            return Object.assign(Object.assign({}, commonProperties), { text: Object.assign(Object.assign({}, commonTextProperties), { text: childNode.innerHTML, fontFace: FONT_FACE.bold }) });
        case 'LI':
            return Object.assign(Object.assign({}, commonProperties), { w: 1196, x: 50, text: Object.assign(Object.assign({}, commonTextProperties), { text: `- ${childNode.innerHTML}`, wordWrapWidth: 1011 }) });
        case 'BR':
            return {
                w: 1246,
                h: 2,
            };
        default:
            return {};
    }
};
const generateTextNode = (childNode) => {
    if (childNode.textContent === '\n' || childNode.textContent === ' ')
        return {
            w: 1246,
            h: 2,
        };
    return Object.assign(Object.assign({}, commonProperties), { text: Object.assign(Object.assign({}, commonTextProperties), { text: childNode.textContent }) });
};
