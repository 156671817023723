import { Registry, Router } from '@lightningjs/sdk';
import BasePlatform, { TV_PLATFORM_TAG } from './base';
import { getRenderPrecision, setAppLaunchType } from '../../helpers';
import { Keys, KeyCodes, ROUTE, ROUTE_ENTITY, USER_OPT_OUT_PREFERENCE, PLAYER_SIZE, } from '../../constants';
import { AppLaunchTypes } from '../analytics/types';
import RouterUtil from '../../util/RouterUtil';
import TVPlatformSingleton from './util/TVPlatformFactory';
import { computeFontSource, fontSpecs } from './util/fonts';
import { APP_IDENTIFIER, ErrorType, LEMONADE_PLATFORM } from './types';
import { DrmType, StreamingProtocol } from '@sky-uk-ott/core-video-sdk-js';
import { SupportedPlatforms } from '../../graphql/generated/types';
export default class XboxPlatform extends BasePlatform {
    constructor() {
        super(...arguments);
        this._platformName = 'xboxone';
        this._lemonadePlatform = LEMONADE_PLATFORM.XBOXONE;
        this._bffPlatform = SupportedPlatforms.XboxOne;
        this._streamingProtocol = StreamingProtocol.DASH;
        this._appIdentifier = APP_IDENTIFIER.XBOXONE;
        this._drmType = DrmType.PlayReady;
        this._devicesSdkConfig = {
            uwp: {
                appName: 'oneapp',
                customCertificateFriendlyName: 'Oneapp',
            },
        };
        /**
         * handle redirecting to the deep link if the initial
         * deep linking event has been captured on app launch
         */
        this.checkDeepLinkNavigation = () => {
            const storedDeepLink = TVPlatformSingleton.get().getDeepLink();
            if (storedDeepLink &&
                !TVPlatformSingleton.get().getDeepLinkRedirected() &&
                storedDeepLink.route !== ROUTE.home) {
                const { route, params } = storedDeepLink;
                if (route === 'media-playback') {
                    RouterUtil.navigateToRoute(ROUTE.video, {
                        entity: ROUTE_ENTITY.videoId,
                        value: params.contentId,
                    });
                }
                else if (route === 'media-details') {
                    RouterUtil.navigateToRoute(ROUTE.showHome, {
                        entity: ROUTE_ENTITY.urlAlias,
                        value: params.contentId,
                    });
                }
                else if (route === 'live') {
                    Router.navigate(ROUTE.live, false);
                }
                TVPlatformSingleton.get().setDeepLinkRedirected(true);
            }
        };
        /**
         * Initialize fonts for the platform
         */
        // @ts-expect-error TS(4114) override annotation
        this.initializeFonts = () => {
            const ss = window.document.styleSheets[0];
            const fontLoadingPromises = fontSpecs.map((spec) => {
                var _a;
                if (spec.src) {
                    const fontStyle = "@font-face { font-family: '"
                        .concat(spec.family, "'; src: ")
                        .concat(computeFontSource(spec), '; font-weight: ')
                        .concat(((_a = spec.descriptors) === null || _a === void 0 ? void 0 : _a.weight) || '', ';}');
                    return ss === null || ss === void 0 ? void 0 : ss.insertRule(fontStyle, ss.cssRules.length);
                }
                return null;
            });
            return Promise.all(fontLoadingPromises);
        };
    }
    get capabilities() {
        return {
            externalAppLinking: true,
            concurrentStreams: true,
        };
    }
    async init() {
        await this.generateDeviceId();
        this._deviceType = 'Xbox One';
        //note: recommended best practices: https://learn.microsoft.com/en-us/windows/uwp/xbox-apps/tailoring-for-xbox
        //turn off gamepad input emulation
        if (window.navigator) {
            // @ts-expect-error TS(2339): Property 'gamepadInputEmulation' does not exist on... Remove this comment to see the full error message
            navigator.gamepadInputEmulation = 'keyboard';
        }
        this._setupEventListeners();
    }
    /**
     * check if the xbox browser window and Windows object is available
     * @returns {boolean}
     * @private
     */
    _xboxWindow() {
        // @ts-expect-error TS(2551): Property 'Windows' does not exist on type 'Window ... Remove this comment to see the full error message
        return window.Windows;
    }
    /**
     * setup UWP (Xbox) level event listeners
     * @private
     */
    _setupEventListeners() {
        if (!this._xboxWindow())
            return;
        //** Event listener: Disable back requested event that navigates away from app **/
        Registry.addEventListener(
        // @ts-expect-error TS(2551): Property 'Windows' does not exist on type 'Window ... Remove this comment to see the full error message
        window.Windows.UI.Core.SystemNavigationManager.getForCurrentView(), 'backrequested', (e) => {
            e.Handled = true;
        });
        //** Event listener: Deep Linking **/
        /*
          UWP has an event fired when a protocol (i.e., nbc:// has been specified)
          This event is fired almost immediately, so we need to capture the route & params
          so we can navigate once our data is loaded and the router is prepared to navigate
        */
        // @ts-expect-error TS(2551): Property 'Windows' does not exist on type 'Window ... Remove this comment to see the full error message
        Registry.addEventListener(window.Windows.UI.WebUI.WebUIApplication, 'activated', (e) => {
            // @ts-expect-error TS(2551): Property 'Windows' does not exist on type 'Window ... Remove this comment to see the full error message
            if (e.kind === window.Windows.ApplicationModel.Activation.ActivationKind.protocol) {
                const uri = e.detail[0].uri;
                const { host } = uri;
                const { queryParsed } = uri;
                const params = this._parseParams(queryParsed);
                setAppLaunchType(AppLaunchTypes.deepLink);
                TVPlatformSingleton.get().setDeepLink(host, params);
            }
        });
    }
    /**
     * exit the application
     * @returns {void}
     */
    exit() {
        if (!this._xboxWindow()) {
            return;
        }
        try {
            window.close();
            super.exit();
        }
        catch (e) {
            this.reportError({
                type: ErrorType.OTHER,
                code: TV_PLATFORM_TAG,
                description: 'Error on XBox exit',
                payload: e,
            });
        }
    }
    /**
     * exit the application and open Peacock application
     * @returns {void}
     */
    exitToPeacock() {
        if (!this._xboxWindow()) {
            super.exitToPeacock();
        }
        try {
            //take user to Peacock Product Page
            const pdpURI = 'ms-windows-store://pdp?ProductId=9PL67R4P9PG5';
            // @ts-expect-error TS(2551): Property 'Windows' does not exist on type 'Window ... Remove this comment to see the full error message
            window.Windows.System.Launcher.launchUriAsync(new window.Windows.Foundation.Uri(pdpURI));
        }
        catch (e) {
            this.reportError({
                type: ErrorType.OTHER,
                code: TV_PLATFORM_TAG,
                description: 'Error on XBox exit to Peacock',
                payload: e,
            });
        }
    }
    /**
     * override lightning stage settings for XBox specific settings
     * reference: https://rdkcentral.github.io/Lightning-SDK/#/plugins/settings?id=settings
     * @returns {object}
     */
    getStageSettings() {
        return {
            enablePointer: false,
            devicePixelRatio: window.devicePixelRatio || 1,
            memoryPressure: 20e6,
        };
    }
    /**
     * override video scaling
     * for XBox, we need to scale down the secondary player but ensure that the full screen video is max
     * @returns {number}
     */
    scaleVideoProperty(val, type) {
        return type === PLAYER_SIZE.SECONDARY ? Math.round(val * getRenderPrecision()) : val;
    }
    /**
     * xbox specific key bindings
     * @returns {boolean}
     */
    getPlatformKeyMapping() {
        const numberLetterKeyMapping = this.getAtoZAndNumberKeyMapping(true);
        return Object.assign(Object.assign({}, numberLetterKeyMapping), { [KeyCodes.xbox.A]: Keys.ENTER, [KeyCodes.xbox.B]: Keys.BACKSPACE, [KeyCodes.xbox.X]: 'GamepadX', [KeyCodes.xbox.LeftTrigger]: Keys.MEDIA_REWIND, [KeyCodes.xbox.RightTrigger]: Keys.MEDIA_FAST_FORWARD, [KeyCodes.xbox.Up]: Keys.ARROW_UP, [KeyCodes.xbox.Down]: Keys.ARROW_DOWN, [KeyCodes.xbox.Right]: Keys.ARROW_RIGHT, [KeyCodes.xbox.Left]: Keys.ARROW_LEFT, [KeyCodes.xbox.LeftThumbUp]: Keys.ARROW_UP, [KeyCodes.xbox.LeftThumbDown]: Keys.ARROW_DOWN, [KeyCodes.xbox.LeftThumbRight]: Keys.ARROW_RIGHT, [KeyCodes.xbox.LeftThumbLeft]: Keys.ARROW_LEFT, [KeyCodes.xbox.GamepadMenu]: Keys.GAMEPAD_MENU, [KeyCodes.xbox.GamepadView]: Keys.GAMEPAD_VIEW });
    }
    /**
     * Advertiser Id is stored on the user profile via the XBox browser object if available
     * @returns {string}
     * @private
     */
    getAdvertiserId() {
        return this._xboxWindow()
            ? // @ts-expect-error TS(2551): Property 'Windows' does not exist on type 'Window ... Remove this comment to see the full error message
                window.Windows.System.UserProfile.AdvertisingManager.advertisingId
            : super.getAdvertiserId();
    }
    /**
     * Сalls the Log.error method with different parameters depending on the passed error.
     * @param e
     * @private
     */
    _handleAdInfoError(e) {
        this.reportError({
            type: ErrorType.OTHER,
            code: TV_PLATFORM_TAG,
            description: e.message.indexOf('undefined') === -1
                ? 'handleAdInfoError:: Missing privileges'
                : 'handleAdInfoError:: Undefined error',
        });
    }
    /**
     * internal function to turn queryParsed from uri into params object
     * @param params
     * @returns {object}
     * @private
     */
    _parseParams(params) {
        const queryParams = {};
        for (let i = 0; i < params.length; i += 1) {
            const { name, value } = params[i];
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            queryParams[name] = value;
        }
        return queryParams;
    }
    get deviceInfo() {
        return {
            primaryHardwareType: 'GamesConsole',
            model: 'None',
            version: 'None',
            manufacturer: 'Microsoft',
            vendor: 'Microsoft',
            osName: 'Windows',
            osFamily: 'Windows',
            osVendor: 'Microsoft',
            osVersion: 'None',
            browserName: 'Symbian Browser',
            browserVendor: 'Microsoft',
            browserVersion: 'None',
            userAgent: window.navigator.userAgent,
            displayWidth: window.innerWidth,
            displayHeight: window.innerHeight,
            displayPpi: 0,
            diagonalScreeenSize: 0,
            connectionIp: 'None',
            connectionPort: 0,
            connectionType: 'None',
            connectionSecure: false,
            applicationId: 'None',
        };
    }
    /**
     * User opt out value for ads  1 = false (disallow), 0 = true (allow)
     * Evaluate the storage (set through privacy settings)
     * The debug override (set in debug menu)
     * Additionally, if user does not have an advertiser id, we should disallow
     * advertiserId will come back as a blank string if not enabled from XBox
     *
     * @returns {boolean}
     */
    getUserOptOut() {
        var _a;
        try {
            const storageOptOut = this.getStorageBasedOptOut();
            return !((_a = this.getAdvertiserId()) === null || _a === void 0 ? void 0 : _a.length) ||
                storageOptOut === USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
                ? USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
                : USER_OPT_OUT_PREFERENCE.ALLOW_SALE;
        }
        catch (e) {
            this._handleAdInfoError(e);
            return super.getUserOptOut();
        }
    }
}
