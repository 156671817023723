import 'string.padstart';
import get from 'lodash/get';
import { Registry, Router, Log } from '@lightningjs/sdk';
import { StreamingProtocol } from '@sky-uk-ott/core-video-sdk-js';
import BasePlatform, { PlatformSubscriptionType, SubscriptionWrapper, TV_PLATFORM_TAG, } from './base';
import { USER_OPT_OUT_PREFERENCE, ROUTE, Keys } from '../../constants';
import RouterUtil from '../../util/RouterUtil';
import { setAppLaunchType } from '../../helpers';
import { AppLaunchTypes } from '../analytics/types';
import { getPersonalPreview } from '../../helpers/preview';
import { APP_IDENTIFIER, ErrorType, LEMONADE_PLATFORM } from './types';
import { SupportedPlatforms } from '../../graphql/generated/types';
import AppConfigFactorySingleton from '../../config/AppConfigFactory';
const APP_ID = {
    premium: 'com.samsung.tv.store',
    standart: 'org.volt.apps', //app id for Smart Hub Samsung Application app/view for standard devices.
};
class TizenSubscriptionWrapper extends SubscriptionWrapper {
    constructor(unsubscribeCb) {
        super();
        this.stale = false;
        this._unsubscribeCb = unsubscribeCb;
    }
    unsubscribe() {
        var _a;
        this.stale = true;
        (_a = this._unsubscribeCb) === null || _a === void 0 ? void 0 : _a.call(this);
    }
}
export default class TizenPlatform extends BasePlatform {
    get capabilities() {
        return {
            externalAppLinking: true,
            concurrentStreams: true,
        };
    }
    get devicePartnerId() {
        var _a, _b;
        return ((_b = (_a = AppConfigFactorySingleton.config) === null || _a === void 0 ? void 0 : _a.identity) === null || _b === void 0 ? void 0 : _b.device_type) || 'samsung';
    }
    constructor() {
        super();
        this._platformName = 'Samsung';
        this._lemonadePlatform = LEMONADE_PLATFORM.TIZEN;
        this._bffPlatform = SupportedPlatforms.SamsungTv;
        this._streamingProtocol = StreamingProtocol.DASH;
        this._appIdentifier = APP_IDENTIFIER.TIZEN;
        this._previewServiceName = 'NBCTileService';
        this.subscribe = (evt, callback) => {
            // Filter stale events
            this._subscriptions = this._subscriptions.filter(({ stale }) => !stale);
            if (!window.webapis)
                return;
            try {
                let eventCallback, id, unsubscribe;
                switch (evt) {
                    case PlatformSubscriptionType.VOICE:
                        eventCallback = () => callback(Boolean(window.webapis.tvinfo.getMenuValue(window.webapis.tvinfo.TvInfoMenuKey.VOICE_GUIDE_KEY)));
                        eventCallback();
                        id = window.webapis.tvinfo.addCaptionChangeListener(window.webapis.tvinfo.TvInfoMenuKey.VOICE_GUIDE_KEY, eventCallback);
                        unsubscribe = window.webapis.tvinfo.removeCaptionChangeListener(id);
                        break;
                    case PlatformSubscriptionType.CC:
                    case PlatformSubscriptionType.BACKGROUND:
                    case PlatformSubscriptionType.FOREGROUND:
                    default:
                        break;
                }
                return new TizenSubscriptionWrapper(unsubscribe);
            }
            catch (e) {
                // Won't TVPlatform.reportError since this is a internal subscription error
                Log.error(TV_PLATFORM_TAG, e);
            }
            return undefined;
        };
        this.setPreview = async () => {
            if (!window.tizen)
                return;
            try {
                const { packageId } = this._getAppInfo();
                const previewServiceName = `${packageId}.${this._previewServiceName}`;
                await new Promise((resolve, reject) => {
                    var _a;
                    (_a = window.tizen) === null || _a === void 0 ? void 0 : _a.application.launchAppControl(new window.tizen.ApplicationControl('http://tizen.org/appcontrol/operation/pick', null, 'imag/jpeg', null, [new window.tizen.ApplicationControlData('caller', ['ForegroundApp'])]), previewServiceName, async () => {
                        Log.info(`Launch success: ${previewServiceName}`);
                        await this._sendServiceMessage(previewServiceName);
                        resolve(true);
                    }, function (e) {
                        Log.info(`Launch failed: ${e.message}`);
                        reject();
                    });
                });
            }
            catch (e) {
                // fail silently
            }
        };
        this.checkDeepLinkNavigation = () => this.handleDeepLink();
        this._sendServiceMessage = async (serviceName) => {
            const value = await getPersonalPreview(this.deviceId);
            if (value) {
                const remotePort = window.tizen.messageport.requestRemoteMessagePort(serviceName, 'PREVIEW_SERVICE');
                if (remotePort)
                    remotePort.sendMessage([
                        {
                            key: 'PREVIEW',
                            value,
                        },
                    ]);
            }
        };
        this._removeUnnecessaryHTMLElement();
        Registry.addEventListener(document, 'appcontrol', this.handleDeepLink);
    }
    async init() {
        this._deviceType = 'Samsung TV';
        if (window.webapis) {
            this._registerRemoteKeys();
            await this.generateDeviceId();
            return;
        }
        // Check if webapi is not available
        fetch('$WEBAPIS/webapis/webapis.js')
            .then(async (response) => {
            if (response.status >= 400)
                await super.generateDeviceId();
        })
            .catch(async () => await super.generateDeviceId());
    }
    /**
     * Сalls the Log.error method with different parameters depending on the passed error.
     * @param e
     * @private
     */
    _handleAdInfoError(e) {
        this.reportError({
            type: ErrorType.OTHER,
            code: TV_PLATFORM_TAG,
            description: e.message.indexOf('undefined') === -1 ? 'Missing privileges' : 'Undefined error',
        });
    }
    /**
     * Checks whether the user has enabled the Limit Ad Tracking feature.
     * @returns {boolean}
     */
    getUserOptOut() {
        try {
            const storageOptOut = this.getStorageBasedOptOut();
            return window.webapis.adinfo.isLATEnabled() ||
                storageOptOut === USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
                ? USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
                : USER_OPT_OUT_PREFERENCE.ALLOW_SALE;
        }
        catch (e) {
            this._handleAdInfoError(e);
            return super.getUserOptOut();
        }
    }
    /**
     * TIFA is a randomized and non-persistent Samsung Smart TV device identifier that can be reset.
     * https://developer.samsung.com/smarttv/develop/api-references/samsung-product-api-references/adinfo-api.html#AdInfoManager-getTIFA
     * @returns {string}
     */
    getAdvertiserId() {
        try {
            return window.webapis.adinfo.getTIFA();
        }
        catch (e) {
            this._handleAdInfoError(e);
            return super.getAdvertiserId();
        }
    }
    exit() {
        try {
            if (window.tizen) {
                this.isActiveAppStore
                    ? this._goBackToAppStore()
                    : window.tizen.application.getCurrentApplication().exit();
            }
        }
        catch (e) {
            this.reportError({
                type: ErrorType.OTHER,
                code: TV_PLATFORM_TAG,
                description: 'Tizen API is not available',
                payload: e,
            });
        }
    }
    exitToPeacock() {
        if (!window.tizen)
            super.exitToPeacock();
        const service = new window.tizen.ApplicationControl('http://tizen.org/appcontrol/operation/view', 'gzcc4LRFBF.Peacock', null, null, null);
        try {
            window.tizen.application.launchAppControl(service, 'gzcc4LRFBF.Peacock', function () {
                Log.info('Service launched');
            }, function () {
                Router.navigate(ROUTE.home);
            }, null);
        }
        catch (e) {
            this.reportError({
                type: ErrorType.OTHER,
                code: TV_PLATFORM_TAG,
                description: 'launchService exception',
                payload: e,
            });
        }
    }
    getPlatformKeyMapping() {
        const numberLetterKeyMapping = this.getAtoZAndNumberKeyMapping(true);
        return Object.assign(Object.assign(Object.assign({}, numberLetterKeyMapping), this._getExtraKeymap()), { 10009: Keys.BACKSPACE, 36: Keys.RETURN, 38: Keys.ARROW_UP, 40: Keys.ARROW_DOWN, 37: Keys.ARROW_LEFT, 39: Keys.ARROW_RIGHT, 27: Keys.EXIT, 10182: Keys.EXIT, 500: Keys.RETURN, 415: Keys.MEDIA_PLAY, 417: Keys.MEDIA_FAST_FORWARD, 412: Keys.MEDIA_REWIND, 19: Keys.MEDIA_PAUSE, 10252: Keys.MEDIA_PLAY_PAUSE, 413: Keys.MEDIA_STOP, 457: Keys.INFO });
    }
    _getAppInfo() {
        return window.tizen.application.getAppInfo();
    }
    /**
     * Check tv AppControl and use PAYLOAD value as a route
     */
    handleDeepLink() {
        if (!window.tizen)
            return;
        try {
            const reqAppControl = window.tizen.application
                .getCurrentApplication()
                .getRequestedAppControl();
            if (reqAppControl) {
                const actionData = get(reqAppControl, 'appControl.data');
                const payload = (actionData === null || actionData === void 0 ? void 0 : actionData.length) && actionData.find((item) => item.key === 'PAYLOAD' && item.value);
                if (payload) {
                    const data = JSON.parse(payload.value);
                    if (data === null || data === void 0 ? void 0 : data.values) {
                        // Remove all leading and trailing slashes
                        const route = data.values.replace(/^\/|\/$/g, '');
                        if (route !== Router.getActiveHash())
                            // For some reason Router.navigate will not work without delay
                            Registry.setTimeout(() => {
                                Router.navigate(route, !RouterUtil.isPlayerRoute(route));
                                setAppLaunchType(AppLaunchTypes.deepLink);
                            }, 100);
                    }
                }
            }
        }
        catch (error) {
            this.reportError({
                type: ErrorType.OTHER,
                code: TV_PLATFORM_TAG,
                description: 'Error handling Tizen deeplink',
                payload: error,
            });
        }
    }
    _getExtraKeymap() {
        const defMap = {};
        for (let i = 0; i < 91; i++) {
            if (i <= 9)
                defMap[i + 96] = String(i);
            if (i > 0 && i < 25)
                defMap[i + 111] = `F${i}`;
            if (i >= 48 && i < 91)
                defMap[i] = String.fromCharCode(i);
        }
        return defMap;
    }
    async generateDeviceId() {
        var _a, _b, _c;
        try {
            if (!this.deviceId) {
                const deviceId = (_c = (_b = (_a = window.webapis) === null || _a === void 0 ? void 0 : _a.productinfo) === null || _b === void 0 ? void 0 : _b.getDuid) === null || _c === void 0 ? void 0 : _c.call(_b);
                if (deviceId) {
                    this.deviceId = deviceId;
                }
                else {
                    await super.generateDeviceId();
                }
            }
            await this.setPreview();
        }
        catch (e) {
            // fail silently
        }
    }
    async getModelNumber() {
        try {
            if (!this._model)
                this._model = window.webapis.productinfo.getRealModel();
            return this._model;
        }
        catch (e) {
            return await super.getModelNumber();
        }
    }
    getFirmware() {
        try {
            return window.webapis.productinfo.getFirmware();
        }
        catch (e) {
            return super.getFirmware();
        }
    }
    getStageSettings() {
        return Object.assign(Object.assign({}, super.getStageSettings()), { readPixelsAfterDraw: true, readPixelsAfterDrawThreshold: 0, pauseRafLoopOnIdle: true });
    }
    _goBackToAppStore() {
        const app = window.tizen.application.getCurrentApplication();
        const shouldExitTheApp = typeof navigator.userAgent === 'string' && navigator.userAgent.includes('Tizen 3.0');
        window.tizen &&
            window.tizen.application.launch(APP_ID.premium, () => {
                shouldExitTheApp && app.exit();
            }, () => {
                window.tizen.application.launch(APP_ID.standart, () => {
                    shouldExitTheApp && app.exit();
                }, () => {
                    window.tizen.application.getCurrentApplication().exit();
                });
            });
    }
    _registerRemoteKeys() {
        var _a;
        if ((_a = window.tizen) === null || _a === void 0 ? void 0 : _a.tvinputdevice) {
            try {
                const supportedKeys = window.tizen.tvinputdevice
                    .getSupportedKeys()
                    .map(({ name }) => name);
                window.tizen.tvinputdevice.registerKeyBatch([
                    Keys.MEDIA_REWIND,
                    Keys.MEDIA_FAST_FORWARD,
                    Keys.MEDIA_PLAY,
                    Keys.MEDIA_PAUSE,
                    Keys.MEDIA_PLAY_PAUSE,
                    Keys.MEDIA_STOP,
                    Keys.EXIT,
                    Keys.INFO,
                ].filter((name) => supportedKeys.includes(name)));
            }
            catch (e) {
                this.reportError({
                    type: ErrorType.OTHER,
                    code: TV_PLATFORM_TAG,
                    description: 'Tizen API is not available',
                });
            }
        }
    }
    _removeUnnecessaryHTMLElement() {
        const childNode = Array.from(document.body.childNodes).find((node) => {
            return node.textContent && node.textContent.trim() === '<';
        });
        if (childNode) {
            document.body.removeChild(childNode);
        }
    }
    get deviceInfo() {
        return {
            primaryHardwareType: 'TV',
            model: 'None',
            version: 'None',
            manufacturer: 'Samsung',
            vendor: 'Samsung',
            osName: 'Linux',
            osFamily: 'Tizen',
            osVendor: 'Tizen',
            osVersion: 'None',
            browserName: 'Symbian Browser',
            browserVendor: 'Samsung',
            browserVersion: 'None',
            userAgent: window.navigator.userAgent,
            displayWidth: window.innerWidth,
            displayHeight: window.innerHeight,
            displayPpi: 0,
            diagonalScreeenSize: 0,
            connectionIp: 'None',
            connectionPort: 0,
            connectionType: 'None',
            connectionSecure: false,
            applicationId: 'None',
        };
    }
}
